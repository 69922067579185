<!-- 任务记录 -->
<template>
	<div class="Site IndexBox" style="padding-top: 48px">
		<div style="font-size: 10px; color: #000">
			<van-nav-bar fixed :border="false" left-arrow @click-left="$router.go(-1)" />
			<div class="title">
				{{ $t("task.default[0]") }}
			</div>
		</div>
		<div class="ScrollBox" style="background-color: #f4f4f5">
			<van-tabbar style="padding-top: 10px; padding-bottom: 15px" :border="false" inactive-color="#ccc" active-color="#000"
			 z-index="99" :fixed="false" icon-size="80" v-model="tabsIndex" @change="changeTabs">
				<!-- 图标 -->
				<van-tabbar-item v-show="item.state != 4" @click="tabbar_item(index)" v-for="(item,index) in taskTabs" :title="item.text"
				 :key="item.state">
					<div class="myicon">
						<img slot="icon" ref="tabbar_item_img" class="iconimg" style="width: 28px; height: 28px" :src="taskTabs[item.state - 1].img" />
					</div>
					<p class="mytext">{{ item.text }}</p>
				</van-tabbar-item>
			</van-tabbar>
			<!-- 日期选择 -->
			<div class="mydate">
				<div class="inpDate">
					<input type="text" readonly v-model="startDate" ref="startDate" @click="
              (showDate = true),
                (currentDate = new Date(startDate)),
                (pickType = 1)
            " />
					<span>—</span>
					<input type="text" readonly ref="endDate" v-model="endDate" @click="
              (showDate = true),
                (currentDate = new Date(endDate)),
                (pickType = 2)
            " />
				</div>
				<div class="seachbg" @click="seach()"></div>
			</div>
			<!-- 数据内容 -->
			<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
				<van-list v-model="isLoad" :finished="isFinished" :finished-text="
            listData[tabsIndex].length ? $t('vanPull[0]') : $t('vanPull[1]')
          "
				 @load="onLoad" :class="{ Empty: !listData[tabsIndex].length }">
					<div class="mygrid">
						<van-cell class="TaskItem" title-class="record" :border="false" v-for="(item, index) in listData[tabsIndex]" :key="item.order_id">
							<!-- @click="onClickCell(item.order_id)"修改前的点击事件 -->
							<template #title>
								<!-- <h4 style="color: #000">
                  {{ item.group_name }}{{ $t("task.default[1]") }}：{{
                    item.group_info
                  }}
                </h4> -->
								<div class="mytab">
									<table>
										<tr class="one">
											<td class="tab_head">
												<div class="time_box" ref="thisDate">
													{{
                            tabsIndex == 0 || tabsIndex == 4
                              ? item.add_time
                              : item.handle_time
                          }}
													<div style="color: #4e51bf;margin-left: 35px;transform: scale(0.8);">{{ $t("task.tabs[0]") }}</div>
												</div>
												<div>
													<button class="tab_button" @click="open(item.task_id)">
														{{ $t("task.tabs[6]") }}
													</button>
												</div>
											</td>
										</tr>
										<tr class="body_one">
											<td>{{ $t("task.default[9]") }}</td>
											<div></div>
											<td>{{ $t("task.default[10]") }}</td>
											<div></div>
											<td>{{ $t("task.default[11]") }}</td>
											<div></div>
											<td>{{ $t("task.default[12]") }}</td>
										</tr>
										<tr class="body_two">
											<td>{{ task }}</td>
											<div></div>
											<td>{{ Collected }}</td>
											<div></div>
											<td>{{ total }}</td>
											<div></div>
											<td>{{ amount }}</td>
										</tr>
									</table>
								</div>
								<!-- <p style="color: #000" v-if="item.requirement">
                  ({{ item.requirement }})
                </p> -->
								<!-- <p> -->
								<!-- {{ $t("task.default[2]") }}： -->
								<!-- {{ item.add_time }} -->
								<!-- </p> -->
								<!-- <p> -->
								<!-- {{ $t("task.default[3]") }}： -->
								<!-- {{
                    tabsIndex == 0 || tabsIndex == 4
                      ? item.add_time
                      : item.handle_time
                  }}
                </p> -->
								<!-- <p class="linkinfo" v-if="item.is_fx != 1">
                  <a :href="item.link_info" target="_blank" @click.stop="">{{
                    $t("task.default[4]")
                  }}</a>
                  <a
                    href="javascript:;"
                    @click.stop="
                      $Util.CopyText(`IosLink${index}`, `AppLink${index}`)
                    "
                    >| {{ $t("task.default[5]") }}</a
                  >
                </p> -->
								<!-- <span
                  :id="`IosLink${index}`"
                  style="position: absolute; opacity: 0"
                  >{{ item.link_info }}</span
                >
                <input
                  :id="`AppLink${index}`"
                  type="text"
                  :value="item.link_info"
                  style="position: absolute; opacity: 0"
                /> -->
							</template>
							<!-- <div class="state" v-if="tabsIndex == 0">
                <div style="color: #fc4e49; font-weight: bold; font-size: 16px">
                  <p>{{ InitData.currency }} {{ Number(item.reward_price) }}</p>
                </div>
                <van-uploader
                  upload-icon="photo"
                  v-model="fileList[index]"
                  :after-read="afterRead"
                  multiple
                />
                <van-button
                  type="info"
                  style="margin-top: 30px; background-color: #587cff"
                  size="mini"
                  round
                  @click.stop="submitTask(item.order_id, index)"
                  >{{ $t("task.default[7]") }}</van-button
                >
                <van-button
                  style="margin: 5px 0"
                  color="#aaa"
                  size="mini"
                  block
                  @click.stop="cancelTask(item.order_id)"
                  >放弃</van-button
                >
              </div>
              <div class="state" v-else>
                <p>
                  <img
                    :src="`./static/icon/state${item.status}-${$i18n.locale}.png`"
                    height="50"
                  />
                </p>
                <van-button
                  style="margin-top: 15px"
                  color="#aaa"
                  size="mini"
                  round
                  @click.stop="cancelTask(item.order_id, index)"
                  v-if="tabsIndex == 1"
                  >{{ $t("task.default[8]") }}</van-button
                >
              </div> -->
						</van-cell>
					</div>
				</van-list>
			</van-pull-refresh>
		</div>
		<van-popup v-model="showDate" position="bottom" close-on-popstate>
			<van-datetime-picker v-model="currentDate" type="date" :title="$t('teamReport.default[16]')" :min-date="minDate"
			 @confirm="confirmDate" @cancel="showDate = false" />
		</van-popup>
		<!-- <Footer /> -->
	</div>
</template>

<script>
	import $ from "jquery";
	export default {
		name: "TaskRecord",
		components: {},
		props: [],
		data() {
			return {
				currentDate: "",
				startDate: this.$Util.DateFormat("YY-MM-DD", new Date()),
				endDate: this.$Util.DateFormat("YY-MM-DD", new Date()),
				showDate: false,
				minDate: new Date(2020, 0, 1),
				listData: "",
				data: "",
				isLoad: false,
				isFinished: false,
				isRefresh: false,
				pageNo: 1,
				tabsState: 1,
				tabsIndex: 0,
				taskTabs: [{
						state: 1,
						text: this.$t('task.tabs[0]'),
						img: "./static/icon/taskbar_1.png",
					},
					{
						state: 2,
						text: this.$t('task.tabs[1]'),
						img: "./static/icon/taskbar_2.png",
					},
					{
						state: 3,
						text: this.$t('task.tabs[2]'),
						img: "./static/icon/taskbar_3.png",
					},
					{
						state: 4,
						text: this.$t('task.tabs[3]'),
						img: "./static/icon/taskbar_4.png",
					},
					{
						state: 5,
						text: this.$t('task.tabs[4]'),
						img: "./static/icon/taskbar_4.png",
					},
					{
						state: 6,
						text: this.$t('task.tabs[5]'),
						img: "./static/icon/taskbar_5.png",
					}
				],
				task: 111,
				Collected: 22,
				total: 5555,
				amount: 1111111,
				fileList: [],
				tabbar_selete: [
					"./static/icon/taskbar_01.png",
					"./static/icon/taskbar_02.png",
					"./static/icon/taskbar_03.png",
					"./static/icon/taskbar_05.png",
					"./static/icon/taskbar_04.png",
					"./static/icon/taskbar_05.png",
				],
				beforeIndex: 0,
			};
		},
		computed: {},
		watch: {},
		created() {
			// this.listData = this.taskTabs.map(item=>'')
			var ll = this.taskTabs.length;
			this.listData = [];
			for (var i = 0; i < ll; i++) {
				this.listData[i] = "";
			}
			console.log("d");
			this.getListData("init");
		},
		mounted() {
			this.tabbar_item(0)
		},
		activated() {},
		destroyed() {},
		methods: {
			// onClickCell(id) {
			//   if (!$(event.target).hasClass("van-uploader__input")) {
			//     this.$router.push(`/user/taskInfo/${id}`);
			//   }
			// },
			tabbar_item(index) {
				this.$refs.tabbar_item_img[this.beforeIndex].src = this.taskTabs[this.beforeIndex].img
				this.beforeIndex = index
				this.$refs.tabbar_item_img[index].src = this.tabbar_selete[index]
			},
			open(id) {
				console.log(id)
				// 任务详情
				this.$router.push(`/user/postTask/${id}`);
				// this.$router.push(`/user/postingDetails/${id}`);
			},
			onLoad() {
				this.getListData("load");
			},
			changeTabs2(index) {
				console.log("aaa" + index);
			},
			changeTabs(index) {
				this.tabsState = this.taskTabs[index].state;
				this.getListData("init");
			},
			getListData(type) {
				this.isLoad = true;
				this.isRefresh = false;
				if (type == "load") {
					this.pageNo += 1;
				} else {
					this.pageNo = 1;
					this.isFinished = false;
				}
				this.$Model.GetTaskRecord({
						status: this.tabsState,
						page_no: this.pageNo,
						is_u: 2
					},
					(data) => {
						this.isLoad = false;
						if (data.code == 1) {
							if (type == "load") {
								this.listData[this.tabsIndex] = this.listData[
									this.tabsIndex
								].concat(data.info);
							} else {
								this.listData[this.tabsIndex] = data.info;
							}
							if (this.pageNo == data.data_total_page) {
								this.isFinished = true;
							} else {
								this.isFinished = false;
							}
						} else {
							this.listData[this.tabsIndex] = "";
							this.isFinished = true;
						}
					}
				);
			},
			onRefresh() {
				this.getListData("init");
			},
			afterRead(file) {
				file.status = "uploading";
				file.message = this.$t("upload[0]");
				this.uploadImgs(file);
			},
			compressImg(file) {
				this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
					let param = new FormData();
					param.append("token", localStorage["Token"]);
					param.append("type", 3);
					param.append("image", image, file.file.name);
					this.$Model.UploadImg(param, (data) => {
						if (data.code == 1) {
							file.message = this.$t("upload[2]");
							file.status = "success";
							file.url = data.url;
						} else {
							file.status = "failed";
							file.message = this.$t("upload[3]");
						}
					});
				});
			},
			uploadImgs(file) {
				if (file.length) {
					file.forEach((item) => {
						if (!item.file.type.match(/image/)) {
							item.status = "failed";
							item.message = this.$t("upload[1]");
							return;
						}
						this.compressImg(item);
					});
				} else {
					if (!file.file.type.match(/image/)) {
						file.status = "failed";
						file.message = this.$t("upload[1]");
						return;
					}
					this.compressImg(file);
				}
			},
			submitTask(id, index) {
				if (this.fileList[index]) {
					const examine_demo = this.fileList[index].map((item) => item.url);
					this.$Model.SubmitTask({
							order_id: id,
							examine_demo: examine_demo
						},
						(data) => {
							if (data.code == 1) {
								this.fileList[index] = [];
								this.getListData("init");
							}
						}
					);
				} else {
					this.$Dialog.Toast(this.$t("task.msg"));
				}
			},
			cancelTask(id, index) {
				this.$Model.SubmitTask({
					order_id: id,
					status: 6
				}, (data) => {
					if (data.code == 1) {
						this.fileList[index] = [];
						this.getListData("init");
					}
				});
			},
			confirmDate(date) {
				if (this.pickType == 1) {
					this.startDate = this.$Util.DateFormat("YY-MM-DD", date);
				}
				if (this.pickType == 2) {
					this.endDate = this.$Util.DateFormat("YY-MM-DD", date);
				}
				this.showDate = false;
			},
			seach(thisDate) {
				console.log(this.startDate);
			},
		},
	};
</script>
<style scoped>
	.title {
		background: #4e51bf;
		color: #fff;
		height: 45px;
		font-size: 18px;
		position: fixed;
		top: 0;
		width: 100%;
		text-align: center;
		line-height: 45px;
	}

	.Site {
		font-family: emoji;
	}

	.Site .van-nav-bar__title {
		font-size: 1.125rem;
		color:#cccccc;
	}

	.van-tabbar>>>.van-tabbar-item {
		padding: 0 10px;
		font-size: 15px;
	}

	.linkinfo {
		margin-left: -5px;
		text-indent: 5px;
	}

	.linkinfo a:link {
		color: #ff8a00;
	}

	.van-uploader {
		display: block;
		margin-top: 10px;
	}

	.van-uploader>>>.van-uploader__upload-icon {
		opacity: 0.3;
		font-size: 16px;
	}

	.van-uploader>>>.van-uploader__wrapper {
		justify-content: flex-end;
		flex-direction: column;
		align-items: flex-end;
	}

	.van-uploader>>>.van-uploader__upload,
	.van-uploader>>>.van-uploader__preview-image {
		width: 36px;
		height: 36px;
		margin-right: 0;
		background-color: #0b273f;
	}

	.van-uploader>>>.van-uploader__preview {
		margin-right: 0;
	}

	.van-uploader>>>.van-uploader__mask-message {
		white-space: nowrap;
	}

	.van-pull-refresh {
		min-height: calc(100vh - 90px);
	}

	.TaskItem {
		padding: 12px 0 0;
		box-shadow: 0px 0px 5px #ccc;
		margin-bottom: 16px;
	}

	.TaskItem .va .TaskItem .van-cell__value {
		overflow: visible;
	}

	.van-list {
		overflow: hidden;
	}

	.myicon {
		background: #4e51bf;
		width: 45px;
		height: 45px;
		margin: auto;
		margin-bottom: 5px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.IndexBox {
		padding-bottom: 0;
	}

	.IndexBox>>>.iconimg {
		width: 26px !important;
		height: 26px !important;
	}

	.IndexBox>>>.van-tabbar {
		background-color: rgb(244, 244, 245);
	}

	.IndexBox>>>.van-tabbar-item {
		background-color: rgb(244, 244, 245);
		color: #444 !important;
		width: 100px;
		margin-top: 5px;
	}

	.IndexBox>>>.van-tabbar-item--active {
		font-weight: 600;
	}

	.IndexBox>>>.van-tabbar {
		display: flex;
		align-items: flex-start;
	}

	.IndexBox>>>.van-tabbar-item__text {
		text-align: center;
	}

	.IndexBox>>>.mytext {
		width: 50px;
		word-wrap: break-word;
		font-size: 12px;
		font-family: emoji;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.mydate {
		display: flex;
		justify-content: space-around;
		margin: 33px 0 10px 0;
		padding: 0 10px;
	}

	.mydate input {
		width: 110px;
		height: 25px;
		border-radius: 20px;
		background-color: #fff;
		border: none;
		text-align: center;
		color: #444;
		font-size: 13px;
	}

	.mydate span {
		padding: 0 14px;
		color: #999;
	}

	.mydate .seachbg {
		width: 46px;
		height: 24px;
		background-color: #fff;
		border-radius: 20px;
		background-image: url("../../../public/static/icon2/seach.png");
		background-size: 18px;
		background-repeat: no-repeat;
		background-position: 15px;
		box-shadow: darkgrey 1px 1px 1px 0px;
	}

	.mytab {
		margin: auto;
	}

	.mytab table {
		margin: auto;
		width: 100%;
	}

	.tab_head {
		color: #000 !important;
		font-size: 12px;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
	}

	.tab_button {
		border: none;
		line-height: 25px;
		border-radius: 15px;
		box-shadow: darkgrey 0px 0px 1px 0.1px;
		background-color: #fff;
		margin-top: -10px;
		font-size: 12px;
		transform: scale(0.9);
		padding: 0 6px;
	}

	.body_one {
		width: 100%;
		border-top: 1px solid #e5e6e8;
		border-bottom: 1px solid #e5e6e8;
		display: flex;
		flex-direction: row;
		color: #000;
	}

	.body_one td {
		width: 100%;
		text-align: center;
		line-height: 34px;
		font-family: emoji;
		font-size: 13px;
	}

	.body_one div {
		height: 10px;
		width: 1px;
		background: darkgrey;
		margin: auto;
		margin-left: 11px;
		margin-right: 10px;
	}

	.body_two div {
		margin-left: 11px;
		margin-right: 10px;
	}

	.body_two {
		line-height: 35px;
		display: flex;
		flex-direction: row;
		color: #fe889d;
		margin-top: 2px;
	}

	.body_two td {
		width: 100%;
		text-align: center;
	}

	.mygrid {
		padding: 10px 16px;
	}

	.time_box {
		display: flex;
	}

	.van-cell>>>.van-cell__title {
		margin: 0 10px;
	}
</style>
